import { useRef } from 'react'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

const ID = process.env.tokenTawkToGX.ID
const KEY = process.env.tokenTawkToGX.KEY
// const setupTawkTo = () => {tawkTo(process.env.tokenTawkToGX.ID, process.env.tokenTawkToGX.KEY)}

const TawkTo = () => {
    const tawkMessengerRef = useRef()
    const handleMinimize = () => {
        tawkMessengerRef.current.minimize()
    }

    return <TawkMessengerReact propertyId={ID} widgetId="default" />
}

export default TawkTo
