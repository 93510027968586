import _ from 'lodash'
import { URL_IMG_EMPTY, URL_THUMBNAIL } from '@/config/urls'
const baseURL = process.env.cms_file || 'http://localhost:1337/api/cms-file'

// Olw without async await
export const _convertArraySingleObject = (parameters = {}) => {
   return _.isObject(parameters) && !_.isEmpty(parameters.data)
      ? parameters.data.map((vm) => {
           let parameter = { ...vm.attributes, id: vm.id }

           Object.keys(parameter).map((pm) => {
              if (
                 !_.isEmpty(parameter[pm]) &&
                 !_.isEmpty(parameter[pm].data) &&
                 !_.isEmpty(parameter[pm].data.attributes)
              ) {
                 // This for check data Object
                 parameter[pm] = _convertSingleObject(parameter[pm])
              } else if (
                 !_.isEmpty(parameter[pm]) &&
                 !_.isEmpty(parameter[pm].data)
              ) {
                 // This for check data Array
                 parameter[pm] = _convertArraySingleObject(parameter[pm])
              }
           })

           return parameter
        })
      : []
}

export const _convertSingleObject = (parameter) => {
   let result = {}

   if (
      _.isObject(parameter) &&
      !_.isEmpty(parameter) &&
      !_.isEmpty(parameter.data)
   ) {
      result = { ...parameter.data.attributes, id: parameter.data.id }

      Object.keys(result).map((key) => {
         if (
            !_.isEmpty(result[key]) &&
            !_.isEmpty(result[key].data) &&
            !_.isEmpty(result[key].data.attributes)
         ) {
            result[key] = _convertSingleObject(result[key])
         }
      })
   }

   return result
}

export const _convertArraySingleObjectWithAsync = async (parameters = {}) => {
   if (_.isObject(parameters) && !_.isEmpty(parameters.data)) {
      const results = await Promise.all(
         parameters.data.map(async (vm) => {
            let parameter = { ...vm.attributes, id: vm.id }

            for (const pm of Object.keys(parameter)) {
               if (
                  !_.isEmpty(parameter[pm]) &&
                  _.isObject(parameter[pm]) &&
                  !_.isEmpty(parameter[pm].data)
               ) {
                  if (!_.isEmpty(parameter[pm].data.attributes)) {
                     parameter[pm] = await _convertSingleObjectWithAsync(
                        parameter[pm]
                     )
                  } else {
                     parameter[pm] = await _convertArraySingleObjectWithAsync(
                        parameter[pm]
                     )
                  }
               }
            }

            return parameter
         })
      )

      return results
   }

   return []
}

export const _convertSingleObjectWithAsync = async (parameter) => {
   let result = {}

   if (
      _.isObject(parameter) &&
      !_.isEmpty(parameter) &&
      !_.isEmpty(parameter.data)
   ) {
      result = { ...parameter.data.attributes, id: parameter.data.id }

      for (const key of Object.keys(result)) {
         if (
            !_.isEmpty(result[key]) &&
            _.isObject(result[key]) &&
            !_.isEmpty(result[key].data)
         ) {
            if (
               !_.isEmpty(result[key].data.attributes) &&
               _.isObject(result[key])
            ) {
               result[key] = await _convertSingleObjectWithAsync(result[key])
            } else if (_.isArray(result[key].data)) {
               result[key] = await _convertArraySingleObjectWithAsync(
                  result[key]
               )
            }
         }
      }
   }

   return result
}

const pathFile = '/api/cms-file'
export const _convertCMSFile = (url) => (url ? pathFile + url : '') //baseURL + url

export const _convertInternalURLFile = (url) => url //process.env.domain + url

export const _checkImgCoverBlog = (dataYoast = {}) =>
   dataYoast && dataYoast.og_image && dataYoast.og_image.length
      ? dataYoast.og_image[0].url
      : null

// ABOUT BLOG MANAGEMENT PARAMETER's
export const _convertBlogTitle = (passData = {}) =>
   passData.title ? passData.title.rendered : ''
export const _convertBlogTitleWithLimit = (passData = {}, limit = 0) =>
   passData.title ? passData.title.rendered?.substring(0, limit) + '...' : ''
export const _convertBlogContent = (passData = {}) =>
   passData.content ? passData.content.rendered : ''
export const _convertBlogSrcBanner = (dataYoast = {}) =>
   dataYoast && dataYoast.og_image && dataYoast.og_image.length
      ? dataYoast.og_image[0].url
      : URL_IMG_EMPTY

export const _convertSlugToTitle = (slug) => {
   let words = slug.split('-')

   for (let i = 0; i < words.length; i++) {
      let word = words[i]
      words[i] = word.charAt(0).toUpperCase() + word.slice(1)
   }

   return words.join(' ')
}

const objectSEOYoast = (from, to) => ({ from, to })
const shapeOfSEOYoast = [
   objectSEOYoast('title', 'title'),
   objectSEOYoast('description', 'description'),
   objectSEOYoast('og_image', 'images'),
]
export const _convertSOEYoast = (dataYoaast = {}) => {
   let data = {}
   shapeOfSEOYoast.map(({ from, to }) => {
      if (!_.isEmpty(dataYoaast[from])) data[to] = dataYoaast[from]
   })

   return data
}

const shapeOFSEOStrapi = [
   objectSEOYoast('title', 'title'),
   objectSEOYoast('description', 'description'),
   objectSEOYoast('thumbnail', 'images'),
]
export const _convertSEOStrapi = (dataSEO = {}) => {
   let data = {}

   shapeOFSEOStrapi.map(({ from, to }) => {
      if (!_.isEmpty(dataSEO[from])) {
         data[to] = dataSEO[from]

         if (from === 'thumbnail' && !_.isEmpty(dataSEO[from])) {
            data[to] = [
               {
                  alt: !_.isEmpty(dataSEO[from].title)
                     ? dataSEO[from].title
                     : 'GlobalXtreme Internet Service Provider | Fiber Broadband, Wireless, TV & Phone | ISP Bali | ISP Malang | ISP Balikpapan | ISP Samarinda | Fiber Broadband, Wireless, TV & Phone',
                  url: !_.isEmpty(dataSEO[from].url)
                     ? _convertCMSFile(dataSEO[from].url)
                     : URL_THUMBNAIL,
                  width: '1200',
                  height: '630',
               },
            ]
         } else if (from === 'thumbnail') {
            delete data[to]
         }
      }
   })

   return data
}
