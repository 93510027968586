import { useRouter } from 'next/router'
import _ from 'lodash'
import { localeLinkRedirect } from '@/content/translate/locale-link'
import { LS_LOCALE } from '@/config/localStorageKey'

export const useHookCheckTranslateLink = () => {
    const router = useRouter()

    const _handleSwitchLang = async (path = '', passLocale = '') => {
        // This function for redirect url to another page
        const locale = passLocale || router.locale

        const findURL = !_.isEmpty(localeLinkRedirect[locale])
            ? localeLinkRedirect[locale].find((vm) => vm.pathname === path)
            : {}

        const redirectTo = !_.isEmpty(findURL) ? findURL.redirect : path

        router.replace(redirectTo, redirectTo, {
            locale: locale,
        })
    }

    const _handleSwitchRedirectToDefault = (content = {}) => {
        // console.log('query: ', router.query)
        // _.isEmpty(content)
        //     ? router.push(router.pathname, router.pathname, {
        //           locale: router.defaultLocale,
        //       })
        //     : null

        // console.log('content: ', content)

        if (_.isEmpty(content)) {
            router.push(
                {
                    pathname: router.pathname,
                    query: { ...router.query },
                },
                router.pathname,
                {
                    locale: router.defaultLocale,
                }
            )
        }
    }

    const _handleSwitchPageByCacheLocale = (passLocale = 'id') => {
        // This function for redirect url to the same page

        const findURL = !_.isEmpty(localeLinkRedirect[passLocale])
            ? localeLinkRedirect[passLocale].find(
                  (vm) => vm.pathname === router.pathname
              )
            : {}

        const redirectTo = !_.isEmpty(findURL)
            ? findURL.redirect
            : router.pathname

        router.push(
            {
                pathname: router.pathname,
                query: { ...router.query },
            },
            redirectTo,
            {
                locale: passLocale,
            }
        )
    }

    return {
        __handleSwitchLang: _handleSwitchLang,
        __handleSwitchRedirectToDefault: _handleSwitchRedirectToDefault,
        __handleSwitchPageByCacheLocale: _handleSwitchPageByCacheLocale,
    }
}
