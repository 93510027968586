export const shapeVOHook = (data = null, handle = () => {}) => ({
    data,
    handle,
})

export const shapeTranslateLocale = (contentEN = '', contentID = '') => ({
    en: contentEN,
    id: contentID,
})

export const shapeTranslateBundle = (parent, name = '', link = '') => ({
    [parent]: { name, link },
})

export const shapeLinkRedirect = (pathname = '', redirect = '', name = '') => ({
    pathname,
    redirect,
    name,
})

export const shapeTranslateLink = (name = '', link = '') => ({ name, link })
