import { _get } from '@/content/cms/_config'
const baseURL = process.env.cms_api || 'http://localhost:1337/api/'

const configURL = (pageOf, locale = 'en', search = '') =>
   baseURL + pageOf + '?populate=deep&locale=' + locale + search

export const contentHome = (locale = 'en') =>
   _get(configURL('page-home', locale))

export const contentProduct = (locale = 'en') =>
   _get(configURL('page-product', locale))

export const contentProductXtremeLite = (locale = 'en') =>
   _get(configURL('page-product-xtreme-lite', locale))

export const contentProductSignature = (locale = 'en') =>
   _get(configURL('page-product-signature', locale))
export const contentProductPreferred = (locale = 'en') =>
   _get(configURL('page-product-preferred', locale))

export const contentProductDedicated = (locale = 'en') =>
   _get(configURL('page-product-dedicated', locale))

export const contentProductCustomizedServices = (locale = 'en') =>
   _get(configURL('page-product-customized', locale))

export const contentAbout = (locale = 'en') =>
   _get(configURL('page-about', locale))

export const contentHeadDepartment = (locale = 'en') =>
   _get(configURL('page-head-department', locale))

export const contentCareer = (locale = 'en') =>
   _get(configURL('page-career', locale))

export const contentContact = (locale = 'en') =>
   _get(configURL('page-contact', locale))

export const contentBilling = (locale = 'en') =>
   _get(configURL('page-billing', locale))

export const contentBlog = (locale = 'en') =>
   _get(configURL('page-blog', locale))

export const contentBranches = () => _get(configURL('branches'))

export const contentPageInfoGeneral = (
   locale = 'en',
   title = '',
   search = ''
) =>
   _get(
      configURL(
         'info-general-pages',
         locale,
         '&filters[page][$eq]=' + title + search
      )
   )

export const contentCustomerSupport = (locale = 'en') =>
   _get(configURL('page-customer-support', locale))

export const contentCustomerTechnicalSupport = (locale = 'en') =>
   _get(configURL('page-customer-technical-support', locale))

export const contentFindQuestions = (locale = 'en', search = '') =>
   _get(
      configURL(
         'customer-supports',
         locale,
         '&pagination[limit]=-1&filters[title][$contains]=' + search
         // + '&filters[$or][description][$contains]=' + search
      )
   )

export const contentCTSOSupport = (locale = 'end') =>
   _get(configURL('page-ctso', locale))
export const contentFindCTSOQuestions = (locale = 'en', search = '') =>
   _get(
      configURL(
         'content-ctsos',
         locale,
         '&pagination[limit]=-1&filters[title][$contains]=' + search
      )
   )

export const contentPackageCoverageArea = () =>
   _get(
      configURL(
         'general-packages',
         '',
         '&pagination[limit]=-1&filters[isCoverageArea][$eq]=true'
         // + '&filters[$or][description][$contains]=' + search
      )
   )

export const contentTypePackages = () => _get(configURL('type-packages'))
